import moment from 'moment'

export const unescapePunctuation = (toUnescape) => {
  let unescaped = toUnescape.replace(/&#8217;/g,"'").replace(/&#038;/g,"&");
  return unescaped;
}

export const formatCommentCountAsHumanized = (comment_count) => {
  const comment_count_as_int = parseInt(comment_count);
  if(comment_count_as_int === 0) {
    return `No comments`;
  } else if(comment_count_as_int === 1) {
    return `${comment_count_as_int} comment`;
  } else {
    return `${comment_count_as_int} comments`;
  }
}

export const formatNotificationCountAsReasonable = (notification_count) => {
  const notification_count_as_int = parseInt(notification_count);
  if(notification_count_as_int < 10) {
    return notification_count_as_int;
  } else {
    return `9+`;
  }
}

export const formatDateAsFromNow = (date) => {
  const date_as_moment = moment(date);
  const today_as_moment = moment();
  if(today_as_moment.diff(date_as_moment, 'days') <= 2) {
    return date_as_moment.fromNow();
  } else {
    return date_as_moment.format('MM DD, YYYY');
  }
}

export const formatDateTimeAsHumanized = (date) => {
  const date_as_moment = moment(date);
  return date_as_moment.format('h:mma [on] MMMM Do, YYYY');
}

export const formatDateAsHumanized = (date) => {
  const date_as_moment = moment(date);
  return date_as_moment.format('MMMM Do, YYYY');
}

export const formatDateAsShort = (date) => {
  const date_as_moment = moment(date);
  return date_as_moment.format('MMM D, YYYY');
}

export const formatUrlAsDomain = (url) => {
  let url_as_domain = url.replace('http://','').replace('https://','');
  url_as_domain = url_as_domain.split('?')[0].split('/')[0];
  const url_parts = url_as_domain.split('.');
  url_as_domain = `${url_parts[url_parts.length - 2]}.${url_parts[url_parts.length - 1]}`;
  return url_as_domain;
}

export const formatUrlAsHttp = (url) => {
  if(url.length > 0 && (url.indexOf('http://') === -1 && url.indexOf('https://') === -1)) {
    return `http://${url}`;
  } else {
    return url;
  }
}

export const formatUrlAsRef = (url) => {
  if(url.length > 0 && url.indexOf('?') === -1) {
    return `${url}?ref=tfttpresents`;
  } else {
    return `${url}&ref=tfttpresents`;
  }
}

export const formatArrayAsAlphaByKey = (array, key) => {
  const compare = (a,b) => {
    if (a[key] < b[key])
      return -1;
    if (a[key] > b[key])
      return 1;
    return 0;
  }
  return array.sort(compare);
}
